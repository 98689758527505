/* eslint-disable @typescript-eslint/ban-ts-comment */
import {Box, Chip, Grid, ListItem, ListItemText, Typography} from '@material-ui/core'
import {ListItemProps} from '@material-ui/core/ListItem/ListItem'
import {makeStyles} from '@material-ui/core/styles'
import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'
import {FC, useCallback, useMemo} from 'react'
import {useTranslate} from 'react-admin'
import {Link, useLocation} from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import Empty from '../../components/Empty'
import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import useSimplifyAge from '../../hooks/useSimplifyAge'
import {Chats} from '../../types/graphqlSchema'
import {userInitials} from '../../utils/users'
import AttentionIndicator from '../AttentionIndicator'
import {FlagIcon, WarningIcon} from '../icons'

const ChatList: FC<ChatListProps> = ({
  chats,
  chatTitle: ChatTitle,
  fetchMoreChats,
  hasMessagePreview,
  hasMoreChats,
  // TODO: The side effects of this prop should by directly implemented by
  //       `ExternalChatPage` because concrete implement details should not leak into
  //       this generic component
  isExternalMultiMerchantUserChat,
  isFetchingMore,
  loading,
  rightComponent: RightComponent,
  selectedChatId,
}) => {
  const styles = useStyles()
  const {hash, pathname} = useLocation()
  const Row = useCallback(({index, style}) => (
    <ChatListItem
      {...{
        ChatTitle,
        RightComponent,
        hasMessagePreview,
        hash,
        pathname,
        selectedChatId,
      }}
      chat={chats[index]}
      isExternalMultiMerchantUserChat={isExternalMultiMerchantUserChat}
      key={chats[index].id}
      style={style}
    />
  ), [
    ChatTitle,
    RightComponent,
    chats,
    hasMessagePreview,
    hash,
    isExternalMultiMerchantUserChat,
    pathname,
    selectedChatId,
  ])
  if (loading && !isFetchingMore) return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection="column"
      gridGap="1rem"
      paddingX="1rem"
    >
      {[...Array(6).keys()]
        .map(i => <Skeleton height="3.5rem" key={i} variant="rect" />)}
    </Box>
  )
  if (!chats.length) return <Empty resource="chats" />
  return (
    <AutoSizer className={styles.root}>
      {({height, width}) => (
        <InfiniteLoader
          isItemLoaded={index => !!chats[index]}
          // We add one (+1) to the chats length to keep the infinite loader active,
          // and it will keep triggering the load more items function. On the other
          // hand, once there are no more chats to load, we don't add the extra 1 in the
          // length, so that the infinite loader will not trigger the load more function.
          itemCount={chats.length + (hasMoreChats ? 1 : 0)}
          loadMoreItems={fetchMoreChats}
        >
          {({onItemsRendered, ref}) => (
            <FixedSizeList
              height={height}
              itemCount={chats.length}
              itemSize={60}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

// TODO: Move into separate file
const ChatListItem: FC<ChatListItemProps> = ({
  chat,
  ChatTitle,
  hash,
  hasMessagePreview,
  isExternalMultiMerchantUserChat,
  pathname,
  RightComponent,
  selectedChatId,
  ...props
}) => {
  const translate = useTranslate()
  const simplifyAge = useSimplifyAge()
  const styles = useStyles()
  const {compileChatMessageText} = useCompileChatMessageText()
  const [, basePath] = pathname.split('/')
  const path = `/${basePath}/${[
    chat.channelId, chat.id,
  ].filter(Boolean).join('/')}${hash}`
  const mostRecentChatMessage = useMemo(() => _.first(chat.chatMessages), [chat])
  // TODO: Move side effects for external chat styling into `ExternalChatPage` component
  const isExternal = useMemo(() => chat.type === 'EXTERNAL', [chat])
  return (
    <ListItem
      // @ts-ignore (on the "button" prop):
      //  Type 'boolean' is not assignable to type 'true'
      button
      className={`
        ${styles.chatListItem}
        ${(isExternalMultiMerchantUserChat && !chat.assignedMerchantUser) ?
        styles.isUnassignedExternalMultiMerchantUserChat : ''
        }
      `}
      component={Link as any}
      selected={chat.id === selectedChatId}
      to={path}
      {...props}
    >
      <Grid className={styles.chatListItemGrid} container>
        {isExternal && (
          <Grid className={styles.leftComponentsGridItem} item>
            {
              // TODO: In hasura, add calculated relationship object `chats.userChat`
              //       so that we can write instead `c.userChat?.isFlagged`
              _.first(chat.userChats)?.isFlagged && (
                <FlagIcon color="warning" size="small" />
              )
            }
            {(isExternalMultiMerchantUserChat && chat.assignedMerchantUser) && (
              <Chip
                className={styles.assignedMerchantUserChip}
                label={userInitials(chat.assignedMerchantUser)}
                size="small"
                style={{background: chat.assignedMerchantUser.userColor}}
              />
            )}
          </Grid>
        )}
        <Grid className={styles.chatMessageGridItem} item>
          <ListItemText
            className={styles.chatMessageListItemText}
            primary={<ChatTitle chat={chat} />}
            secondary={hasMessagePreview &&
              (compileChatMessageText(mostRecentChatMessage) ||
                translate('chat.chatList.withoutText'))
            }
            secondaryTypographyProps={{
              variant: "caption",
            }}
          />
        </Grid>
        <Grid className={styles.chatMessageAgeGridItem} item>
          <Typography color="textSecondary" variant="caption">
            {simplifyAge(
              mostRecentChatMessage?.timestamp ?? chat?.lastActivityTimestamp as string
            )}
          </Typography>
        </Grid>
        <Grid className={styles.unreadIndicatorGridItem} item>
          {!!chat.unreadMessageCount && <AttentionIndicator />}
        </Grid>
        <Grid alignItems="center" container item>
          {chat.hasUnreadFailedChatMessage && (
            <WarningIcon color="error" size="inherit"/>
          )}
        </Grid>
        {RightComponent && (
          <Grid className={styles.rightComponentGridItem} item>
            <RightComponent
              chat={chat}
              isExternalMultiMerchantUserChat={!!isExternalMultiMerchantUserChat}
            />
          </Grid>
        )}
      </Grid>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  assignedMerchantUserChip: {
    '& > span.MuiChip-label': {
      textOverflow: 'clip', // Overwrite default 'ellipsis' value
    },
    color: theme.palette.background.paper,
    cursor: 'inherit', // overwrite default behavior of `<Chip>`
    height: '18px',
    width: '32px',
    ...theme.typography.overline,
  },
  chatListItem: {
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    display: 'flex',
    height: '3.75rem',
  },
  chatListItemGrid: {
    '& > .MuiGrid-item': {
      padding: 0,
    },
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 0,
    width: '100%',
  },
  chatMessageAgeGridItem: {
    textAlign: 'end',
    width: '10rem',
  },
  chatMessageGridItem: {
    marginLeft: theme.remSpacing(1),
    overflow: 'hidden',
    width: '100%',
  },
  chatMessageListItemText: {
    '& .MuiListItemText-primary > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiListItemText-secondary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  isUnassignedExternalMultiMerchantUserChat: {
    '&:hover': {
      backgroundColor: '#FBEFEF',
    },
    backgroundColor: '#FBEFEF',
  },
  leftComponentsGridItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '4rem',
  },
  rightComponentGridItem: {
    width: 'auto',
  },
  root: {
    '& .MuiListItem-root:last-of-type': {
      borderBottom: `1px solid ${theme.palette.disabled.main}`,
    },
  },
  unreadIndicatorGridItem: {
    textAlign: 'right',
    width: '2rem',
  },
}))

interface ChatListProps {
  chatTitle: FC<{chat: Chats}>
  chats: Chats[]
  fetchMoreChats: (startIndex: number, stopIndex: number) => Promise<void>
  hasMessagePreview: boolean
  hasMoreChats: boolean
  isExternalMultiMerchantUserChat?: boolean
  isFetchingMore: boolean
  loading: boolean
  rightComponent?: FC<{chat: Chats, isExternalMultiMerchantUserChat: boolean}>
  selectedChatId: string
}

interface ChatListItemProps extends ListItemProps {
  ChatTitle: FC<{chat: Chats}>
  RightComponent?: FC<{chat: Chats, isExternalMultiMerchantUserChat: boolean}>
  chat: Chats
  hasMessagePreview: boolean
  hash: string
  isExternalMultiMerchantUserChat?: boolean
  pathname: string
  selectedChatId: string
}

export default ChatList
